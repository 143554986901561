<template lang="pug">
div
  .brand-wrapper.re-integration
    .container-fluid
      .row.pb-5
        .col-12.align-self-center
          om-heading(h1) {{ getTitle }}
    .wrapper
      .alert-block-re-integration
        template(v-if="!isAllReIntegrated")
          alert(type="warning" :integrationType="getIntegrationType")
        template(v-else)
          success(:integrationType="getIntegrationType")
      .deprecated-integrations
        deprecated-integrations(
          :integrationType="getIntegrationType"
          :filteredIntegrations="filteredIntegrations"
          :campaignsByStatus="campaignsByStatus"
        )
</template>
<script>
  import { capitalizeFirstLetter } from '@/util';
  import { mapGetters } from 'vuex';
  import { getConfigForIntegration } from '@om/integrations';
  import reIntegrationMixin from '@/components/ReIntegration/reIntegration';

  export default {
    components: {
      Alert: () => import('@/components/ReIntegration/Alerts/Alert.vue'),
      Success: () => import('@/components/ReIntegration/Alerts/Success.vue'),
      DeprecatedIntegrations: () => import('@/components/ReIntegration/DeprecatedIntegrations.vue'),
    },
    mixins: [reIntegrationMixin],
    data() {
      return {
        campaignsByStatus: {},
      };
    },
    computed: {
      ...mapGetters(['integrations']),
      getIntegrationType() {
        return this.$route.query.integrationType;
      },
      filteredIntegrations() {
        return this.integrations.filter(({ type }) => type === this.getIntegrationType);
      },
      isDeprecated() {
        return getConfigForIntegration(this.getIntegrationType).deprecated || false;
      },
      needReIntegration() {
        return this.filteredIntegrations.length > 0 && this.isDeprecated;
      },
      getTitle() {
        if (!this.isDeprecated) {
          return this.$t(`reIntegration.noNeedReIntegration`, {
            integrationType: capitalizeFirstLetter(this.getIntegrationType),
          });
        }

        return this.$t('reIntegration.pageTitle', {
          integrationType: capitalizeFirstLetter(this.getIntegrationType),
        });
      },
    },
    async mounted() {
      if (this.filteredIntegrations?.length) {
        const integrationIds = this.filteredIntegrations.map((i) => i._id);
        const campaigns = await this.getCampaignsByIntegrationId(integrationIds);
        this.campaignsByStatus = campaigns;
      }
    },
    methods: {
      capitalizeFirstLetter,
    },
  };
</script>
<style lang="sass">
  @import '@/components/ReIntegration/reIntegration.sass'
</style>
